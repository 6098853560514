import React from 'react';
import './Header.css';
import logo from '../img/logo6.png';

const Header = () => {
	return (
		<div className="header">
			<div className="header__logo">
				<img src={logo} alt="logo" width={140} />
			</div>
			<div className="header__text">
				<text className="header__text-text">
					DidYouMissThis - Here you will find all your good old days videos!
				</text>
				<text className="header__text-text" id="text-alt">
					- This is just a collection of the most epic clips from Youtube
				</text>
				<text className="header__text-text" id="text-alt">
					- And a beginning of a cummunity just for laughs
				</text>
				<text className="header__text-text" id="text-alt">
					- Subscribe to this channel and share!
				</text>
				<text className="header__text-text" id="text-alt">
					- Maybe you did miss this?
				</text>
			</div>
		</div>
	);
};

export default Header;
