import React, { useState } from 'react';
import { Header, VideoDetail, VideoList } from './components';
import { icecracking, icedelivery, blackice, carsplash, snakeclimb, catjump, logo } from './img';
import './App.css';
import Footer from './components/Footer';

const App = () => {
	const [ videoDetailOn, setVideoDetailOn ] = useState(false);

	const [ selectedVideo, setSelectedVideo ] = useState({
		id: 7,
		title: 'Awesome Clips #1',
		url: 'DTWyxx0yRVQ',
		thumbnail: logo
	});

	const myVideos = [
		{ id: 1, title: 'Nature Art: thin ICE cracks over Edge', url: '1qOfGAyCiyc', thumbnail: icecracking },
		{ id: 2, title: 'Icy Driveway: give this Delivery Guy a RAISE!', url: 'Eh2QLLJcvOc', thumbnail: icedelivery },
		{ id: 3, title: 'Icy Road: BLACK ICE', url: 'wX0bTkDNwmY', thumbnail: blackice },
		{ id: 4, title: 'ROAD RAGE: car SPLASH water on another car', url: 'O67ZJwZY09I', thumbnail: carsplash },
		{ id: 5, title: 'Must see this BIG Snake climbing a tree', url: 'CQUFBu1ucN8', thumbnail: snakeclimb },
		{ id: 6, title: 'How I enter a party in my dreams vs. reality', url: '7y2tpgUvHGg', thumbnail: catjump },
		{ id: 7, title: 'Awesome Clips #1', url: 'DTWyxx0yRVQ', thumbnail: logo }
	];

	const onVideoSelect = (video) => {
		setVideoDetailOn(true);
		setSelectedVideo(video);
	};

	return (
		<div className="app-container">
			<Header />
			<div className="main-container">
				{videoDetailOn && <VideoDetail video={selectedVideo} />}
				<VideoList videos={myVideos} onVideoSelect={onVideoSelect} />
			</div>
			<Footer />
		</div>
	);
};

export default App;
