import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import './VideoItem.css';

const VideoItem = ({ video, onVideoSelect }) => {
	// const videoSrc = `https://www.youtube.com/embed/${video.url}`;

	return (
		<div className="video-item">
			<div className="video-item__video" onClick={() => onVideoSelect(video)}>
				<img className="thumbnail" alt="thumbnail" src={video.thumbnail} width="120" height="80" />
				<Typography className="video-item-title" variant="subtitle1">
					<p>{video.title}</p>
				</Typography>
			</div>
		</div>
	);
};

export default VideoItem;
