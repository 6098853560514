import React from 'react';
import './Footer.css';

const Footer = () => {
	return (
		<div className="footer">
			<p className="text">
				<a href="https://www.instagram.com/didyoumissthisyt/" target="_blank" rel="noreferrer">
					INSTAGRAM
				</a>
			</p>
			<p className="text">
				<a href="https://www.youtube.com/channel/UCgIrN6GPQ8UuHL6Hjjg2xEQ" target="_blank" rel="noreferrer">
					YOUTUBE
				</a>
			</p>
			<p className="text">LAST UPDATE: APR 2022</p>
		</div>
	);
};

export default Footer;
